@import 'vars.scss';

.t-contact {
    /*.page-body {
        padding-top:150px;
    } */
    .contact-wrapper {
        background-image: url('../assets/contact-page-bg.svg');
        background-position: top left;
        background-size: cover;
        background-repeat: no-repeat;
        color:white;
        margin-top: -15em;
        .contact-details {
            background-color: white;
            color:#2C2C2C;
            border-radius: 30px;
            padding:2em;
            margin:10vw 0;
            @include drop-shadow;
            .form-header {
                display: flex;
                .form-logo {
                    margin-right:2em;
                }
            }        
            form {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                p {
                    width:47%;
                    margin:0.5em 1em;
                    &.full {
                        width:100%;
                    }
                }
                .recaptcha-notice {
                    width:100%;
                }
                input,
                button,
                textarea {
                    border-radius: 30px;
                }
                input, 
                textarea {
                    border: #BEBEBE 1px solid;
                    margin-top:0.5em;
                }
                button {
                    background-color: $primary_colour;
                    padding: 1em 2em;
                    color:white;
                    border:none;
                }
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            list-style: none;
            padding:0;            
            margin:0;
            /*  margin:8vw 0; // this is more appropriate if there are child elements but :has() not supported yet */ 
            li {
                width:48%;
                border-top:1px solid white;
                line-height: 6;
                &:nth-last-child(2),
                &:nth-last-child(1) {
                    border-bottom:1px solid white;
                }
            }
        }
    }
}
@media only screen and (max-width: 1200px) {
    .t-contact .contact-wrapper {        
        margin-top: -5em;
        .contact-details {
            max-width: 600px;
            margin: 0 auto;
            form {
                display: block;
                p,
                p.full {
                    width: auto;
                }
            }
            .form-header {
                flex-direction: column;
            }
        }
    }
}
@media only screen and (max-width: 960px) {
    .t-contact .contact-wrapper {        
        margin-top: 0;
    }
}