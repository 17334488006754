/* ==========================================================================
   content lists & content items
   ========================================================================== */

.t-shared-index {
	.list {
		padding-bottom: 10%;
	}
	/* no gap between case studies and blocks/footer */
	&.t-testimonials .list {
		padding-bottom: 0px;
	}
}

/* currently only staff index & item have a page-title */
.page-title {
	font-size: 60px;
	line-height: 1.2;
}

/** list pager style **/
.pager {
	text-align: center;
	li {
		 a {
		    @include gradient-button;
		    padding: 10px 5px;
		    min-width: 42px;
		    box-sizing: border-box;
		    margin: 5px;
		 }
		 &.active a {
		    background: rgba(#2C2C2C, 0.5);
		    &:hover {
		        opacity: 1;
		    }
		 }
		 &.disabled {
			display: none;
		 }
	}
}

/** ============ staff list (& staff block list) ============ **/
.staff-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    .staff-person {
        width:30%;
        margin:1%;
        a {
            transition: color 0.3s ease-in-out;
            .staff-graphic {
                transition: border-color 0.3s ease-in-out;
            }
            &:hover {
                color: $primary_colour;
                .staff-graphic {
                    border-color: $primary_colour;
                }
            }
        }
    }
    .staff-graphic {
        margin-bottom:2vw;
    }
}
.staff-person {
	position: relative;
	&.has-phone {
		.details {
			padding-right: 55px; // space for phone icon
		}
	}
	a {
		display: block;
		&.staff-phone {
			position: absolute;
			bottom: 0px;
			right: 0px;
			z-index: 10; /* over the bio link */
		}
	}
}
.staff-graphic {
    border-radius:30px;
    border:1px solid #FFFFFF;
    background-color: rgba(255,255,255,0.35);
    overflow:hidden;
	box-sizing: border-box;
    img {
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        object-fit: contain;
    }
}


/** ============ staff bio ============ **/
.t-staff-person {
	.page-body {
		padding-bottom: 10%;
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			clear:both; /** for graphic **/
		}
	}
	.staff-graphic {
		display: inline-block;
		width: clamp(300px, 50%, 640px);
		margin: 0px 40px 40px 0px;
		float: left;
	}
	.page-title {
		margin-top: 0px;
	}
}

@media only screen and (max-width: 960px) {
	.t-staff-person {
		.staff-graphic {
			margin: 0px;
			margin-bottom: 30px;
			float: none;
		}
	}
}
@media only screen and (max-width: 760px) {
	.staff-list  {
		justify-content: center;
		justify-content: space-evenly;
		.staff-person {
			width: 48%;
			padding: 10px;
			margin: 0px;
			box-sizing: border-box;
			.staff-phone {
				right: 10px;
				bottom: 10px;
			}
		}
	}
}
@media only screen and (max-width: 500px) {
	.page-title {
		font-size: 45px;
	}
	.staff-list .staff-person {
		width: 100%;
		max-width: 300px;
		padding: 15px 10px;
	}
}

@media only screen and (max-width: 300px) {
	/** move the call button over the pic **/
	.staff-person.has-phone {
		.details {
			padding-right: 0px;
		}
		.staff-phone {
			top: 0px;
			right: 0px;
			bottom: auto;
		}
	}
}

/** FYI: testimonials styles in testimonials file **/