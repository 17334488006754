@import 'vars.scss';
/*============ header ============*/

/** base template (used by most templates) **/
	/* IF there is a page graphic, use white header & graphic in header will have a bg */
	/* ELSE we'll have a coloured header & transparent bg */
.graphic-wrap {
	&.has-img {
		position: relative;
		color: #FFF;
		background-color: #000;
		@include overlay;
		height: 27vw;
		min-height: 200px;
		header.top {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 10;
		}
	}
	.page-graphic {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
}

/** this is used in home and solutions (list) templates **/
	/* they have a bg colour and the header (& banner-content) is white */
.banner-wrap {
	position: relative;
    background-position: center;
    background-size: cover;
    color: #FFFFFF;
    background-color: #000000; //overridden by home template
    .banner-wrap-content {
        position: relative;
        display: flex;
        flex-direction: column; /* fill height */
        min-height: clamp(400px, 90vh, 1080px);
		padding-top: 100px; /** space for header sub-nav hovers - adjusted in JS */
		box-sizing: border-box;
		z-index: 10;
		.banner-text {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-grow: 1; /** fill height **/
            width: 100%;
            /** move up a smidge **/
            margin-top: -100px;
            box-sizing: border-box;
            h2 {
                max-width: 30vw;
                line-height: 0.9;
            }
        }
    }
}
header.top {
	position: relative;
	background-color: transparent;
	z-index: 11;
	/*
    &.absolute {
        position: absolute;
        left:0;
        right:0;
        z-index: 20;
        .main-menu li.depth-0:last-child a {
			background-color: white;
			color:black;
		}
    }
    &.static {
        background:white;
        .main-menu li.depth-0:last-child a {
			border-radius: 25px;
	        @include brand-gradient;
		}
        #mobile-menu > span {
            background-color: black;
        }
    }*/
    li {
	    vertical-align:top;
	}
	.phone-button {
		margin: 5px; /* align with the list items */
	}

	/** base template **/
	&.colour {
        .main-menu li.depth-0:last-child a {
			border-radius: 25px;
	        @include brand-gradient;
		}
        #mobile-menu > span {
            background-color: black;
        }
    }

	/** used inside banner-wrap **/
	&.white {
		color: #FFF;
        .main-menu li.depth-0:last-child a {
			background-color: white;
			color:black;
		}
    }
}

.logo h1 {
    margin:0;
}

.main-menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; // bottom of logo
    ul {
	    a {
	        padding:15px;
	    }
	}
	/** top menu only **/
	> ul {
		text-align:justify;
	    line-height:0;
	    text-align: right;
	    flex-grow:1;
	    &::after {
	        content:"";
		    display:inline-block;
		    width:100%;
		    line-height:0px;
		    vertical-align:top;
	    }
	}
	li {
	    line-height:1;
	    /** sub-menu's */
	    > ul {
	        display: none;
	        text-align: left;
	        position: absolute; /* to parent li */
	        top: 100%;
	        left: 0px;
	        min-width: 200px;
	        box-sizing: border-box;
	        z-index: 5;
	    }
	    li {
	        display: block;
	    }
	    /** top level only **/
	    &.depth-0 {
	        position: relative;
	        vertical-align:middle;
	        margin: 5px;
	        margin-bottom: 0px;
	        padding-bottom: 5px;
	        /** only level 1 will show */
	        &:hover {
	            > ul {
	                display: block;
	            }
	        }
	        &:last-child a {
	            @include header_button;
	        }
	    }
	}
}
/** hidden
.sub-menu {
    background:#2C2C2C;
    color:#FFFFFF;
    ul {
        text-align: center;
        li {
            margin:0.5em 1em;
            &.active {
                opacity: 0.5;
            }
        }
    }
}
**/
#mobile-menu {
    display: none;
    position: absolute;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    padding:15px;
    background: rgba(0,0,0,0.4);
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index:100;
}
#mobile-menu > span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #fff;
    margin: 6px 0;
    transition: all 0.5s ease;
}
.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.slide-menu-active #mobile-menu span.three {
     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
}
.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}
/***** Slide menu ****/
.slide-menu {
    display:none;
}
.slide-menu {
	background: #eee;
    color:black;
    width:240px;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	right: -240px;
	overflow-y:scroll;
}
.slide-menu-active .slide-menu {
    right: 0px;
}
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.slide-menu .menu-title {
	padding: 1em;
	color:#fff;
	background:$primary_colour;
	margin:0;
	font-weight:bold;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu ul ul {
	background:#ccc;
}
.slide-menu ul ul ul{
	background:#ccc;
}
.slide-menu li {
    position:relative;
    display: block;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}
.slide-menu a {
	display: block;
	font-size: 16px;
	font-weight: 300;
	border-bottom: 1px solid #030b16;
	padding: 18px;
	text-decoration:none;
}
.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid #030b16;
    margin-right: 50px; /* space for drop */
}
.slide-menu a:hover {
	background: $primary_colour;
    color:#fff;
}
.slide-menu li.has-sub > a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: #030b16;
    width: 50px;
    height: 1px; /* border bottom */
    z-index: 100;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #030b16;
    height: 60px;
    width: 50px;
    cursor: pointer;
}
.slide-menu ul .expand i {
    content:"";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    #mobile-menu {
        display:block;
    }
    .main-menu {
        padding-right: 60px; // for the mobile-menu
        ul {
            display:none;
        }
    }
    header .phone-button {
        margin-right: 80px;
        margin-top: 15px;
    }
    .slide-menu {
        display:block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
    .slide-menu-active #mobile-menu {
        right: 260px;
    }
    .sub-menu ul {
        padding-right: 0;
    }
/** don't need space for home submenu hover anymore **/
    .banner-wrap  {
        .banner-wrap-content {
            padding-top: 20px !important;
            min-height: 70%;
            .banner-text {
				margin-top: 0px;
	        }
        }
    }
    header.top {
        padding-bottom: 0px !important;
    }
}
@media only screen and (max-width: 760px) {
    .sub-menu  {
        display: none;
    }
}
@media only screen and (max-width: 500px) {
    header .phone-button {
        display: none;
    }
}