@import 'vars.scss';

.we-live-your-mission {
    background-color: $primary_colour;
    background-image: url('../assets/live-your-mission-bg.jpg');
    color:#ffffff;
    position: relative;
    aspect-ratio: 1920/953;
    background-size: cover;
    h2 {
        font-size: 5vw;
        text-align: center;
        line-height: 0.8;
        position: absolute;
        width:40vw;
        left:50%;
        top:50%;
        margin:0;
        transform: translateX(-50%) translateY(-50%);
    }
    .button {
        background: white;
        color:#2C2C2C;
        position: absolute;
        bottom:3vw;
        left:50%;
        transform: translateX(-50%);
    }
}
@media only screen and (max-width: 760px){
    .we-live-your-mission {
        padding:1em;
        h2 {
            top:25%;
            font-size: 24px;
            margin: 1em auto;
        }   
    }
}
@media only screen and (max-width: 500px){
    .we-live-your-mission {
        aspect-ratio: auto;
        text-align: center;
        h2,
        .button {
            position: relative;
            transform: none;
            left:auto;
            width:auto;
        }
    }
}