
/*============ solutions item ============*/
.t-solution {
	.page-content {
		position: relative;
		padding-top: 10vw;
		// position the bg circle above the content (behind header)
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -100px;
			left: 0px;
			width: 60%;
			width: 60vw;
			height: calc(100% + 100px); // push ^ behind header by 100px
			background-image: url('../assets/service-bg.svg');
		    background-repeat: no-repeat;
		    background-position: top left;
		    background-size: contain;
		}
	}
    .page-title {
        font-weight: normal;
        margin:0;
    }
    .service-graphic {
        float:right;
    }
    .page-body {
        padding-bottom:10%;
    }
}
.sub-solution {
    padding: 10% 0;
    blockquote {
        font-size: 24px;
        font-weight: 300;
        max-width: 300px;
        .button {
            margin-top:100px;
        }
        margin-bottom:-100px;
    }
}
.sub-solution:nth-child(2n+1) {
    @include brand-gradient;
    .button {
        @include white-button;
    }
    blockquote .button {
        @include brand-gradient;
        color:white;
    }
    .lined-list {
        @include lined-list(white);
    }
}

@media only screen and (max-width: 960px){
/** make bg circle full width **/
	.t-solution .page-content:before {
		width: 100%;
	}
}
