.testimonial {
    position: relative;
    display: block;
    padding:200px 0;
    .testimonial-text {
        padding: 8vw 0;
        padding-right:50%;
    }
    .testimonial-graphic {
        position: absolute;
        left:calc(50% + 2vw);
        right:-2vw;
        top:50%;
        margin-top:-20vw;
        height: 40vw;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include drop-shadow;
        background-size: cover;
    }
    h3 {
        display: inline-block;
        color: $primary_colour;
    }
    .highlight {
        font-size:30px;
    }
}
.t-page .testimonial {
    .divider::before {
        border-bottom-color: white;
    }
    h3 {
        color:white;
    }
    .testimonial-text .highlight {
        color:white;
        font-size: 2vw;
    }
}
.t-testimonials .banner-wrap{ 
    position: relative;
    &::before {
        content:"";
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width:100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 1;
    }
}
.testimonial-index {
    .testimonial-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        background-color: #F8F0E6;
        color:#2C2C2C;
        picture {
            width:50%;
            flex-basis: 50%;
            flex-shrink: 0;
            img {
                height: 100% !important;
                object-fit: cover;
            }
        }
        .testimonial-item-content {
            padding: 2em;
            padding-bottom: 10px;
            max-width:math.div($body_width, 2);
            align-self: center;
            width:100%;
            box-sizing: border-box;
        }
        .secondary-brand {
            display: none;
        }
    }
    .testimonial-item:nth-child(2n+1) {
        justify-content: flex-end;
        picture {
            order:1;
        }
    }
    .testimonial-item:nth-child(3n+1) {
        background-color:#FF2848;
        color:#FCFDF5;
        .primary-brand {
            display: none;
        }
        .secondary-brand {
            display: inline;
        }
        .divider::before {
            border-bottom-color:white;
        }
        .button {
            background: white;
            color:#0B323E;
        }
    }
    .testimonial-item:nth-child(3n+3) {
        background-color: #6E1462;
        color:#FCFDF5;
        .primary-brand {
            display: none;
        }
        .secondary-brand {
            display: inline;
        }
        .divider::before {
            border-bottom-color:white;
        }
        .button {
            background: white;
            color:#0B323E;
        }
    }
}
@media only screen and (max-width: 1250px) {
    .testimonials-index {
        .testimonial-item, .testimonial-item:nth-child(2n+1)  { 
            .testimonial-item-content { 
                padding:1em;
            }
        }
    }
}
@media only screen and (max-width: 760px) {
    .testimonial {
        padding: 10vw 0;
        .testimonial-graphic {
            position: static;
            margin-top:1em;
            left:auto;
            right: auto;
            height: auto;
            aspect-ratio: 16/9;
        }        
        .testimonial-text {
            padding-right: 0;
        }
    }
    .t-page .testimonial .testimonial-text .highlight {
        font-size:18px;
    }
    .testimonial-index {
        .testimonial-item, .testimonial-item:nth-child(2n+1)  { 
            flex-direction: column;
            align-items: center;
            picture {
                width: auto;
                order:0;
            }
            .testimonial-item-content { 
                width: 100%;
            }
        }
    }
}