$primary_colour:#FE2848;
$secondary_colour:#561651;
$body_width: 1200px; 
@mixin white-button {    
    background: white;
    color:#2C2C2C;
}
@mixin header_button {
    display: inline-block;
    background-color: white;
    color:#2c2c2c;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold;
    padding: 15px 2em;
    border-radius: 25px;
    &:hover {
        opacity: 0.8;
    }
}
@mixin drop-shadow {
    box-shadow: 30px 0px 50px rgba(44, 94, 113, 0.1);
}
@mixin brand-gradient {
    color:white;
    background: #e6193b;
    background: linear-gradient(135deg, #e6193b 0%, #d3183d 14%, #a21745 42%, #561651 89%, #561651 100%);
}
@mixin gradient-button {
	display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 20px 30px;
    margin: 20px 0;
    max-width: 100%;
    @include brand-gradient;
    box-sizing: border-box;
    transition: opacity 0.3s ease;
    border-radius: 30px;
    font-weight: normal;
    &:hover {
        opacity: 0.8;
    }
}
@mixin turn-and-scale {
    transition: transform 0.5s ease;
    &:hover {
        transform: scale(1.1) rotate(5deg);
    }
}
@mixin lined-list($fg_colour) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    padding:0;
    margin:8vw 0;
    li {
        width:48%;
        border-top:1px solid $fg_colour;
        line-height: 6;
        white-space: nowrap;
        &:nth-last-child(2),
        &:nth-last-child(1) {
            border-bottom:1px solid $fg_colour;
        }
    }
    @media only screen and (max-width: 760px) {
        li {
            width: 100%;
            text-align: center;
        }
    }
}
@mixin overlay {
	&::before { /** darken overlay **/
        content:"";
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width:100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 1; /* over any page-graphic */
    }
}