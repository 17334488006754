.t-page {
    /*.page-body {
        padding-top:10%;
    } */
    .after-content {
        background-image: url('../assets/page-bg.svg');
        background-position: top left;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color:white;
        .page-subtitle {
            font-size:3vw;
            font-weight: 300;
            p {
                max-width: min(math.div($body_width, 2), 48vw);
            }        
            padding-top: 16vw;
            padding-bottom:8em;
            &::after {
                content:"";
                position: absolute;
                bottom:0;
                height: 8em;
                border-left:1px solid white;
                left:0;
            }
        }
        .testimonial-text {
            max-width: 80vw;
        }
    }
}
@media only screen and (max-width: 960px) {
    .t-page {
        .after-content {
            .page-subtitle {
                margin-left:10px;
            }
        }
    }
}
@media only screen and (max-width: 760px) {
    .t-page .after-content .page-subtitle {
        padding-top:50%;
        font-size:18px;
    }
}
@media only screen and (max-width: 450px){
    .t-page {
        .after-content {
            /* replace svg with different curve */
            background-color: $primary_colour;
            background-image: none;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            .page-subtitle {
                padding-top: 20px;
                padding-bottom: 60px;
                p {
                    max-width: 100%;
                }
                &::after {
                    left: 20px;
                    height: 50px;
                }
            }
            .testimonial {
                padding-top: 10px;
            }
            .testimonial-text {
                max-width: 100%;
                padding: 0px;
                .button {
                    margin-bottom: 0px;
                }
            }
        }
    }
}