/*============ splash ============*/
.t-home #content {
    padding:15% 0;
}
.t-home .banner-wrap {
    @include brand-gradient;
    h2 {
        font-size: 5vw;
        margin-right:2vw;
        text-align: center;
    }
    .banner-text {
        justify-content: center;
    }
}
/*  banner */
.banners-partners .banner-stage {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.banners-partners .banner-image {
    margin:20px;
    background-repeat: no-repeat;
    opacity: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banners-partners .banner-name {
    display: none;
}
.banners-partners .banner-navigation {
    display:none;
}
.t-home .solutions-menu {
    background-color: $primary_colour;
    color:#ffffff;
    padding:5%;
    text-align: center;
    ul {
        max-width: #{math.div($body_width * 2, 3)};
        margin:0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        li {
            width:15%;
        }
        a {
            display: flex;
            flex-direction: column;
            img {
                margin-bottom:1.5em;
            }
        }
    }
}
.related-page {
    padding:6vw 0;
    position: relative;
    background-size: cover;
    .contentwrap {
        height:100%;
    }
    .related-content-intro {
        display: inline-block;
        color: $primary_colour;
        color: #2c2c2c;
    }
    h2 {
        font-size: 4vw;
        line-height: 1;
        margin:0.5em 0;
    }
    .related-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
    &:nth-child(2n+1) .divider::before {
        border-bottom-color: white;
    }
}
.related-page:nth-child(2n+1) {
    background-color: $primary_colour; /* we are expecting an image over this */
    color:white;
    aspect-ratio: 64/39;
    .button {
        @include white-button;
    }
    .icon {
        position: absolute;
        top:0%;
        left:60%;
        right:15%;
        bottom:0;
        img {
            object-fit: contain;
            object-position: center;
            height: 100% !important;
            width:100%;
        }
    }
    .related-body {
        padding-right:50%;
    }
    .related-content-intro {
        color:white;
    }
}
.related-page:nth-child(2n) {    
    .icon {
        position: absolute;
        top:-20%;
        left:0;
        right:50%;
        bottom:0;
        img {
            object-fit: contain;
            object-position: bottom left;
            height: 100% !important;
            width:100%;
            &.turn-and-scale {
                &:hover {
                    transform: none;
                }
            }
        }
    }
    .related-body {
        padding-left:50%;
    }
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1300px) {

}
@media only screen and (max-width: 960px) {
    .banners-partners .banner-image {
        margin:10px;
    }
    
    .related-page {
        .contentwrap {
            height:auto;
        }
    }
    .t-home .banner-wrap .banner-wrap-content .banner-text {
        flex-direction: column;
        h2 {
            font-size: 48px;
            max-width: 50vw;
        }
    }
}

@media only screen and (max-width: 760px) {
    .related-page:nth-child(2n+1) {
        .icon {
            position: static;
        }
        .related-body {
            padding-right: 0;
        }
    }
    .t-home .solutions-menu ul {
        flex-wrap: wrap;
        justify-content: space-evenly;
        li {
            width:33%;
            margin:1em;
        }
    }
}
@media only screen and (max-width: 500px) {
    .t-home .solutions-menu ul {
        display: block;
        li {
            width:150px;
            display: block;
            margin: 1em auto;
        }
    }
    .related-page:nth-child(2n) {    
        padding-bottom:0;
        .icon {
            position: static;
            top:auto;
            left:auto;
            right:auto;
            bottom:auto;
        }
        .related-body {
            padding-left:0;
        }
    }
}