/*============ footer ============*/
footer.bottom {
    .site-map {
        background-color: #F8F0E6;
        color: #2C2C2C;
        padding:10% 0;
        font-size:14px;
        .contentwrap {
            display: flex;
            nav {
                flex-grow: 1;
                & > ul {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    li {
                        font-weight: bold;
                        margin: 0 2em;
                        li {
                            font-weight: normal;
                            margin:0;
                            li {
                            	font-size: 0.75em;
                            	a {
                            		line-height: 1.2;
                            	}
                            }
                        }
                    }
                }
            }
        }
    }
    .copyright {
        .contentwrap {
            display: flex;
            padding:1em;
            padding-bottom:2em;
        }        
        background-color: #2C2C2C;
        color: #FFFFFF;
    }
}
.site-map > ul {
    display:table;
    width:100%;
}
.site-map > ul > li {
    display:table-cell;
    padding:20px 0;
}
.site-map li li {
    display:block;
    margin:10px 0;
}
.site-map a {
    color:inherit;
    text-decoration:none;
    display:block;
    padding:0 15px;
    position:relative;
    line-height:30px;
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    flex-grow: 1;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}



/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    footer.bottom .site-map .contentwrap {
        display: block;
        .secondary-logo {
            display: none;
        }
    }
}
@media only screen and (max-width: 760px) {
    footer.bottom {
        .site-map { 
            .logo {
                text-align: center;
                margin-bottom:1em;
            }
            a {
                text-align: center;
            }
        }
        .copyright {
            .contentwrap {
                flex-direction: column-reverse;
                align-items: center;
            }
        }
    }
}