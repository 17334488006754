/*============ solutions index ============*/
.t-solutions .banner-wrap {
    @include overlay;
    h2 {
        font-size: 7vw;
        margin-left:2vw;
    }
    .solutions-menu {
        position: relative;
        height: 140px;
        bottom:-50px;
        background:#FFFFFF;
        color:#0B323E;
        width: 100%;
        //left:50%;
        //margin-left: math.div(-$body_width, 2);
        border-radius: 30px;
        padding:20px;
        box-sizing: border-box;
        ul {
            height: 100%;
            display: flex;
            align-items: stretch;
            justify-content: space-evenly;
            li {                
                border-left:#123D4E solid 1px;
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                .title {
                    margin-left:1em;
                }
            }
            li:first-child {
                border-left:none;
            }
        }
    }
}
.solution-tiles {
    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        li {
            aspect-ratio: 1;
            padding:10%;
            padding-top: calc(10% + 50px); /** for the banner-menu overlap */
            padding-left:30%;
            background-color: #F8F0E6;
            color:#2C2C2C;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            height: 100%;
            max-width: 100%;
            .secondary-brand {
                display: none;
            }
            a {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .title {
                    font-size: 50px;
                    font-family: degular, sans-serif;
                    margin-bottom:1vw;
                }
            }
            .button {
                margin-top:2em;
            }
        }
        li:nth-child(2n){
            padding-left:10%;
            padding-right:30%;            
        }
        li:nth-child(1) {
            background-color:#FF2848;
            color:#FCFDF5;
            .primary-brand {
                display: none;
            }
            .secondary-brand {
                display: inline;
            }
            .divider::before {
                border-bottom-color:white;
            }
            .button {
                background: white;
                color:#0B323E;
            }
        }
        li:nth-child(4) {
            background-color: #6E1462;
            color:#FCFDF5;
            .primary-brand {
                display: none;
            }
            .secondary-brand {
                display: inline;
            }
            .divider::before {
                border-bottom-color:white;
            }
            .button {
                background: white;
                color:#0B323E;
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .t-solutions  {
        .banner-wrap {
            min-height: 0px;
	        .solutions-menu {
	            height: auto;
		        ul li {
		            flex-basis: 0;
		            align-items: stretch;
		            a {
		                display: flex;
		                flex-direction: column;
		                width:100%;
		                align-items: center;
		                justify-content: flex-start;
		                .title {
		                    text-align: center;
		                    margin:0 1em;
		                }
		            }
		        }
	        }
        }
    /** don't need space for the submenu hover anymore **/
		.page-content {
		    padding-top: 10% !important;
		}
    }
    .solution-tiles ul {
        display: block;
        li,
        li:nth-child(2n) {
            padding:10%;
            aspect-ratio: auto;
        }
        li:nth-child(3) {
            background-color: #6E1462;
            color:#FCFDF5;
            .primary-brand {
                display: none;
            }
            .secondary-brand {
                display: inline;
            }
            .divider::before {
                border-bottom-color:white;
            }
            .button {
                background: white;
                color:#0B323E;
            }
        }
        li:nth-child(4) {
            background-color: #F8F0E6;
            color:#2C2C2C;
            
            .primary-brand {
                display: inline;
            }
            .secondary-brand {
                display: none;
            }
            .divider::before {
                border-bottom-color:$primary_colour;
            }
            .button {
                @include brand-gradient;
            }
        }
        
    }
}
@media only screen and (max-width: 760px) {
    .t-solutions .banner-wrap .solutions-menu {
        display: none;
    }
}